/* Bold variant */
@font-face {
    font-family: 'MarkForMC';
    src: url('./fonts/MarkForMC-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

/* Extra Light variant */
@font-face {
    font-family: 'MarkForMC';
    src: url('./fonts/MarkForMC-ExtraLt.woff2') format('woff2');
    font-weight: 200; /* Adjust the font-weight as needed */
    font-style: normal;
}

/* Light variant */
@font-face {
    font-family: 'MarkForMC';
    src: url('./fonts/MarkForMC-Lt.woff2') format('woff2');
    font-weight: 300; /* Adjust the font-weight as needed */
    font-style: normal;
}

/* Medium variant */
@font-face {
    font-family: 'MarkForMC';
    src: url('./fonts/MarkForMC-Med.woff2') format('woff2');
    font-weight: 500; /* Adjust the font-weight as needed */
    font-style: normal;
}

/* Regular variant */
@font-face {
    font-family: 'MarkForMC';
    src: url('./fonts/MarkForMC.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

/* Narrow variant */
@font-face {
    font-family: 'MarkForMC';
    src: url('./fonts/MarkForMCNrw.woff2') format('woff2');
    font-weight: normal; /* Adjust if this variant has a specific weight */
    font-style: normal;
}


body {
    font-family: 'MarkForMC', sans-serif;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #141413;
}
